import Vue from 'vue'
import createAuth0Client from '@auth0/auth0-spa-js'

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

let instance

export const getInstance = () => instance

export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance

  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null,
        loginRequired: false,
        prompt: 'none'
      }
    },
    methods: {
      async loginWithPopup(options, config) {
        this.popupOpen = true

        try {
          await this.auth0Client.loginWithPopup(options, config)
          this.user = await this.auth0Client.getUser()
          this.isAuthenticated = await this.auth0Client.isAuthenticated()
          this.error = null
        } catch (e) {
          this.error = e
        } finally {
          this.popupOpen = false
        }
      },
      async handleRedirectCallback() {
        this.loading = true
        try {
          await this.auth0Client.handleRedirectCallback()
          this.user = await this.auth0Client.getUser()
          this.isAuthenticated = true
          this.error = null
        } catch (e) {
          this.error = e
        } finally {
          this.loading = false
        }
      },
      loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o)
      },
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o)
      },
      getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o)
      },
      getTokenWithPopup(o) {
        return this.auth0Client.getTokenWithPopup(o)
      },
      logout(o) {
        if (this.auth0Client) return this.auth0Client.logout(o)
      },
      async createdMethod() {
        if (process.env.VUE_APP_MODE_OFFLINE === 'true') {
          console.warn(
            '[DEVELOPMENT] Local Offline Mode enabled. Using fake Auth client'
          )
          this.auth0Client = {
            getTokenSilently() {
              return new Promise((resolve) => resolve('localfallbacktoken'))
            }
          }
        } else {
          if (
            window.location.search.includes('error=') &&
            window.location.search.includes('state=')
          ) {
            const errorType = new URL(location.href).searchParams.get('error')
            if (errorType === 'consent_required') {
              // This state is only exposed for _new_ users running in a localhost setup
              // for this we need to expose the oauth constent UI
              // Set the 'prompt' option to true and retry the auth
              this.prompt = true
            } else {
              this.loginRequired = true
              const params = window.location.search.slice(1).split('&')

              params.forEach((param) => {
                const parts = param.split('=')
                if (parts[0] === 'error') {
                  this.error = parts[1]
                }
              })
            }
          }

          this.auth0Client = await createAuth0Client({
            ...options,
            client_id: options.clientId,
            redirect_uri: redirectUri,
            audience: 'https://localhost/query',
            cacheLocation: 'localstorage',
            prompt: this.prompt
          })
        }

        try {
          if (
            window.location.search.includes('code=') &&
            window.location.search.includes('state=')
          ) {
            const { appState } = await this.auth0Client.handleRedirectCallback()
            this.error = null
            this.loginRequired = false
            onRedirectCallback(appState)
          }
        } catch (e) {
          this.error = e
        } finally {
          if (process.env.VUE_APP_MODE_OFFLINE === 'true') {
            this.isAuthenticated = true
            this.user = { sub: 'fakelocalsub' }
            this.loading = false
          } else {
            this.isAuthenticated = await this.auth0Client.isAuthenticated()
            this.user = await this.auth0Client.getUser()
            this.loading = false
          }
        }
      }
    },
    async created() {
      return this.createdMethod()
    }
  })

  return instance
}

export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options)
  }
}
